<template>
  <div class="card">
    <slot></slot>
  </div>
</template>
<style>
.card {
  border-radius: 8px;
  border: 1.5px solid #707070;
  box-sizing: border-box;
  margin: 0 9px 18px;
  overflow: hidden;
}
</style>